.amenities-tab {
  background-color: #FAFAFA;
  height: 100%;

  p {
    margin: 0;
  }

  .left-sidebar {
    border-right: 1px solid #CED4DA;
    padding: 0;
    width: 280px;

    .header {
      border-bottom:  1px solid #CED4DA;
      padding: 0 12px 0 30px;
      min-width: 280px;
      min-height: 52px;

      p {
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
        color: #737980;
      }

      button {
        width: 104px;
        height: 28px;
      }
    }

    .amenity {
      border-bottom:  1px solid #CED4DA;
      border-right:  1px solid #CED4DA;
      min-width: 280px;
      min-height: 40px;
      gap: 26px;
      background-color: #F4F4F4;
      cursor: pointer;

      p {
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 120px;
      }

      .amenity-icon {
        height: 16px;
        padding-left: 20px;
        padding-right: 12px;
      }

      &:hover {
        &:not(.amenity-selected) {
          background-color: #D7DADA;
        }
      
        button {
          visibility: visible;
          background-color: #D7DADA;
          border: none;
          border-radius: unset;
        }
      }

      .button-section {
        min-height: 40px;
      }

      button {
        visibility: hidden;
        background-color: #D7DADA;
        border: none;
        border-radius: unset;
        box-shadow: none;
      }
      .edit-button:hover {
        background-color: #BCBFBF;
      }
      .delete-button:hover {
        background-color: #BCBFBF;
      }
      .edit-button:active, .delete-button:active {
        box-shadow: none;
      }
    }

    .amenity-selected {
      background-color: #D9EAFF;
      border-left: 4px solid #007BFF;

      .amenity-icon {
        padding-left: 16px;
      }
    }
  }

  .right-sidebar {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .add-amenity-text {
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #737980;
    }
  }
}
