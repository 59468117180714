.property-editor {
    .property-building {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        transition: background-color 0.2s ease-in-out;
    }

    .property-wrapper {
        display: flex;
        width: 100%;
    }
    
    .property-row-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .top-rounded {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    .w-20 {
        width: 75px;
    }

    .w-40 {
        min-width: 200px;
    }

    .bottom-rounded {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        border-bottom: 1px solid #E3E3E3;
    }

    .building-text {
        min-width: 150px;
    }
    
    .container {
        display: flex;
        flex-direction: column;
    }

    .input {
        border: 1px solid #CED4DA;
        color: #495057;
        border-radius: 6px;
        height: 38px;
        padding: 14px 7px;
    }

    .trash-icon {
        pointer-events: none;

        &-container {
            padding: 6px;
            padding-top: 19px;
            padding-left: 20px;
            height: fit-content;
            width: fit-content;
            cursor: pointer;
        }
    }

    .trash-icon-section {
       fill: #999C9F;
       color: #999C9F;
    }

    .trash-icon-section:hover {
        fill: #DC3545;
        color: #DC3545;
    }

    .property-row {
        display: flex;
        align-items: center;
        background-color: #FAFAFA;
        border-left: 1px solid #E3E3E3;
        border-right: 1px solid #E3E3E3;
        border-top: 1px solid #E3E3E3;
        color: #616A72;
        height: 62px;
        padding-right: 12px;
        padding-left: 20px;

        &-title {
            padding-right: 20px;
            font-size: 0.75rem;
            font-weight: 600;
            min-width: 3rem;
        }
    }

    .property-floor-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
    }
    
    .property-floor-title {
        font-size: 1rem;
        font-weight: 500;
    }
    
    .property-floor-number {
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .property-floor-digit {
        font-weight: 700;
    }

    .btn-secondary {
        color: #495057;
        background-color: #EBEBEB;
        border-color: #EBEBEB;
        
        &:active,
        &:hover {
            color: #495057 !important;
            background-color: #E3E3E3 !important;
            border-color: #E3E3E3 !important;
        }
    }
}

.btn {
    font-weight: 500;
}