.explorer3d {
  &-gap {
    gap: 1.875rem;
  }

  &-field-tooltip {
    display: flex;
    align-items: center;

    svg {
      margin-left: 0.25rem;
    }
  }

  &-form {
    font-size: 1rem;
    font-weight: 500;

    .form-group {
      &:last-child {
        margin-bottom: 0;
      }

      input.form-control {
        font-weight: 500;
      }
  
      input.form-control::placeholder {
        color: #6C757D      ;
      }

      input[type="file"] {
        display: none !important;
      }

      label.file-upload-label {
        display: inline-block;
        margin-bottom: 0;
        padding: 0.375rem 0.75rem;
        cursor: pointer;
        color: #007bff;
        text-align: center;
        vertical-align: middle;
        user-select: none;
        background-color: transparent;
        border: 1px solid #007bff;
        border-radius: 0.25rem;
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.5;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        &:hover {
          color: #fff;
          background-color: #007bff;
          border-color: #007bff;
        }
      }

      .file-upload-subtitle {
        margin-left: 0.5rem;
        color: #495057;
        font-weight: 400;
      }
    }
  }

  &-header {
    margin-bottom: -0.625rem;
  }

  &-header-gap {
    gap: 0.5rem;
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }

  &-header-title {
    margin-bottom: 0;
    margin-right: 0.5rem;
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 3rem;
  }

  &-header-btn {
    display: flex;
    padding: 0.3rem 0.75rem;
    font-size: 1rem;
    font-weight: 500;
  }
  
  &-item-title {
    margin-bottom: 1rem;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5rem;
  }

  input[type="file"] {
    opacity: 0 !important;
  }

  .color-picker {
    position: absolute;
    top: 50%;
    right: 5px;
    height: 28px;
    width: 45px;
    border: none;
    transform: translate(0, -50%);

    input[type="color"] {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }

    &-container {
      width: 100%;
      height: 100%;
      border-radius: 3px;
      border: 1px solid #CED4DA;
      cursor: pointer;
    }
  }

  &-collapse {
    width: fit-content;
    color: #007bff;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    span {
      margin-right: 0.5rem;
    }

    svg {
      transition: all 0.2s ease-in-out;
    }

    &-open svg {
      transform: rotate(180deg);
    }
  }
}
