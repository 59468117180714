$primary-color: #177EE5;
$primary-light-color: #3399FF;
$primary-lighter-color: #E5EEFF;
$secondary-light-color: #AAFFC7;
$secondary-color: #5FCC9C;
$secondary-dark-color: #215B63;
$warning-color: #FDE7BD;
$warning-dark-color: #7F6C46;
$light-black-color: #343a40;
$light-gray-color: #f8f9fa;
$header-height: 3.3rem;
$nav-tabs-height: 1.7rem;

.with-popup {
  &-popover {
    position: absolute;
    top: 50%;
    max-width: unset;
    transform: translate(calc(-100% - 0.5rem), -50%);
    transition: opacity 0.1s ease-in-out;
    background-color: $light-gray-color;
    box-shadow: 0px 5px 10px 0px rgba(#001A33, 0.1);
    
    .popover-body {
      padding-bottom: 0.75rem;
    }

    .arrow {
      top: 50%;
      transform: translate(0, -50%);
      margin: 0;
    }

    .arrow::after {
      border-left-color: $light-gray-color;
    }
  }

  &-title {
    margin-bottom: 0.2rem;
    font-size: 0.9rem;
    color: $secondary-dark-color;
  }

  &-subtitle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 150px;
    margin-top: 0.4rem;
    background-color: white;
    font-size: 1.6rem;
    font-style: italic;
    color: $secondary-dark-color;
    border: 1px solid rgba($secondary-dark-color, 0.2);
    border-radius: 0.2rem;
  }

  &-expandBtn {
    position: absolute;
    top: 0.5rem;
    right: 0.75rem;
    width: 1.25rem;
    height: 1.25rem;
    color: $primary-color;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    svg {
      width: 100%;
      height: 100%;
    }

    &:hover {
      color: $primary-light-color;
    }
  }

  &-image {
    position: relative;
    width: 200px;
    height: 200px;
    margin-top: 0.4rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.2rem;
    overflow: hidden;
    background-repeat: no-repeat;
    background-color: white;
    transition: all 0.4s ease-in-out;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &-image-svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  &-property {
    fill: $secondary-color;
  }

  &-property-secondary {
    fill: $warning-color;
  }
}

.editor-modal {
  padding: 0 !important;
  overflow: hidden !important;
  font-family: Poppins, sans-serif;
  
  .popover-body {
    font-family: Poppins, sans-serif;
  }

  &-header {
    display: flex;
    width: 500px;
  }

  &-buttons {
    position: absolute;
    right: 1rem;
    display: flex;
    gap: 0.75rem;
  }

  &-header .form-group {
    margin-bottom: 0;
  }

  .button-primary {
    color: $light-gray-color;
  }

  .button-secondary {
    color: $light-gray-color;
    background-color: rgba($secondary-dark-color, 0.85);
    border-color: rgba($secondary-dark-color, 0.9);
    
    &:hover {
      background-color: $secondary-dark-color;
      border-color: $secondary-dark-color;
    }
    
    &:disabled {
      background-color: rgba($secondary-dark-color, 0.85);
      border-color: rgba($secondary-dark-color, 0.9);
    }
  }

  .modal-title {
    margin-right: 2rem;
    font-size: 1.2rem;
    font-weight: bold;
  }

  .modal-dialog {
    max-width: 100%;
    height: 100%;
    margin: 0;
  }

  .modal-content {
    height: 100%;
  }

  .modal-header {
    position: relative;
    height: $header-height;
    padding-bottom: 0;
    border: none;
  }

  &-start .modal-header {
    justify-content: flex-start;
    align-items: center;
  }

  .editor-wrapper {
    max-height: calc(100vh - ($nav-tabs-height + $header-height + 2rem));
    width: 100%;
    overflow: auto;
  }
  
  .rg-pane.rg-pane-left,
  .rg-pane.rg-pane-top {
    z-index: 1;
  }

  .rg-pane-shadow.shadow-top-left-corner {
    z-index: 3;
  }

  .rg-celleditor-input, .rg-celleditor input {
    border: none;
  }

  .reactgrid-content .rg-pane .rg-cell.rg-checkbox-cell label span:after {
    left: 7px;
    top: 2px;
  }
  
  .rg-header-cell {
    background-color: $primary-color !important;
  }
  
  & .editor-wrapper-floor-plan {
    .rg-header-cell {
      background-color: $secondary-dark-color !important;
    }
  }

  .reactgrid-content .rg-pane .rg-cell {
    overflow: visible;
    
    &.rg-text-cell.rg-invalid {
      color: #dc3545;
    }
  }
    
  .rg-cell-nonEditable {
    color: rgba($light-black-color, 0.75) !important;

    & label input:checked ~ span,
    & label input ~ span {
      background-color: rgba($light-black-color, 0.25) !important;
    }
  }
  
  .reactgrid-content .rg-pane .rg-cell.rg-checkbox-cell label {
    cursor: auto;
  }

  .reactgrid-content .rg-pane .rg-cell.rg-checkbox-cell label:hover input ~ span {
    background-color: $primary-color;
  }

  .reactgrid-content .rg-pane .rg-cell.rg-checkbox-cell label input ~ span {
    background-color: rgba($primary-color, 0.8);
    transition: background-color 0.1s ease-in-out;
  }
  
  .cell {
    &-dimmed {
      background-color: rgba($primary-color, 0.07) !important;
    }
    
    &-dimmed.rg-cell-nonEditable,
    &-dimmed-success.rg-cell-nonEditable {
      background-color: $light-gray-color !important;
      
      & label input:checked ~ span,
      & label input ~ span {
        background-color: rgba($light-black-color, 0.25) !important;
      }
    }
    
    &-dimmed-success {
      background-color: rgba($secondary-color, 0.25) !important;
    }

    &-renderer {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }
  
  .cell-dimmed + .rg-withPopup-cell.rg-cell-nonEditable {
    background-color: rgba($light-gray-color, 1) !important;
  }

  .header-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    gap: 8px;
    transition: color 200ms ease-in-out;
    color: #fff;
    
    &-icon {
      position: relative;
      top: 1px;
      display: flex;
      width: 1rem;
      color: #fff;
      transition: color 200ms ease-in-out;
    
      & > svg {
        width: 100%;
        height: 100%;
        transition: transform 200ms ease-in-out;
      }

      &-reverted svg {
        color: $secondary-light-color;
        transform: rotate(180deg);
      }
    }

    &-sorted {
      text-decoration: underline;
      color: $secondary-light-color;
    }
  }

  .tab-content > .tab-pane {
    display: block;
    visibility: hidden;
    height: 0;

    .editor-container {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  
  .tab-content > .active {
    visibility: visible;
    height: 100%;

    .editor-container {
      margin-top: 1rem;
    }
  }

  .editor-tabs {
    height: $nav-tabs-height;
    padding-left: 1rem;

    & > a {
      display: flex;
      align-items: center;
      margin-right: 2px;
      padding: 0.1rem 0.6rem;
      font-size: 0.8rem;
      color: #fff;
      border: 3px solid #fff;
      border-bottom: none;
      transition: border-color 0.1s ease-in-out;
    }
    
    & > a:hover {
      border-color: transparent;
    }
    
    & > a:nth-child(1) {
      background-color: $primary-color !important;
    }
    
    & > a:nth-child(2) {
      background-color: $secondary-dark-color !important;
    }
    
    & > a.nav-link.active {
      color: #fff;
      border-color: transparent;
    }
  }

  &-alert {
    .modal-content {
      font-family: Poppins, sans-serif;
      background-color: $light-gray-color;
      border: 1px solid $light-black-color;
      box-shadow: 0px 5px 20px 0px rgba(#001A33, 0.3);
    }

    .modal-header {
      border: none;

      p {
        margin-bottom: 0;
        font-size: 1.1rem;
      }
    }

    .modal-footer {
      border: none;
    }
  }

  &-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    font-size: 2rem;
  }
}
