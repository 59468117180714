$spacer: 5px;

.gap-4 {
  gap: $spacer * 3;
}

.gap-2 {
  gap: $spacer * 2;
}

.gap-1 {
  gap: $spacer;
}

.bg-grey {
  background-color: #e3e3e3;
}

.bg-black {
  background-color: #1f1f1f;
  color: white;
}

.no-padding {
  padding: 0;
}

.no-margin {
  margin: 0;
}

.content-label {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  margin-bottom: 24px;
}

.content-description {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  width: 360px;
}

.content-editor-item {
  display: flex;
  margin-bottom: 16px;
  padding-left: 0;
}

.form-check-label {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

.drop-files-container {
  background: #fafafa;
  flex-direction: column;
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
  height: 158px;
  padding: 0;
  position: relative;
  border: 1px solid #ced4da;
  border-radius: 12px;
  cursor: pointer;
}

.drop-files-container h1 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  margin-bottom: 0;
}

.drop-files-container button {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  padding: 7px 14px 7px 14px;
  gap: 6px;
  border-radius: 4px;
}

.form-control-file {
  width: 100%;
  height: 100%;
  z-index: 1000;
  // opacity: 0;
  position: absolute;
  cursor: pointer;
  z-index: 0;
}

.drop-files-container-opacity {
  opacity: 0.2;
}

.loader {
  position: absolute;
  top: 20px;
  right: 20px;
}

.content-editor-image-container {
  height: 100%;
  width: 100%;
  background: #e3e3e3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.content-editor-image-container h1 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #495057;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

.form-check-input {
  width: 1rem;
  height: 1rem;
}

.btn {
  font-weight: 500;
}

.btn-download {
  color: #fff;
  background-color: green;
  border-color: green;
  margin-right: 5px;
  position: relative;
}

.btn-view {
  color: #fff;
  background-color: #cccccc;
  border-color: #cccccc;
  margin-right: 5px;
  position: relative;
}

.btn-danger {
  margin-right: 5px;
  position: relative;
}

.delete-alert {
  position: relative;
  background-color: #cccccc;
}

.dropdown-item {
  z-index: 10000;
}
