.floor-tab {
    background-color: #FAFAFA;
    width: 100%;
    padding: 0;
    height: 100%;

    p {
        margin: 0;
    }


    .right-sidebar {
        width: 100%;
        background-color: rgba(0, 0, 0, 0.05);

        .upload-text {
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
        }

        .upload-container {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
        }

        .svg-container {
            position: relative;
            display: flex;
            min-height: 80%;
          
            justify-content: center;
            align-items: center;
    
            .bg {
                max-height: calc(100vh - 122px);
                width: auto;
            }
        
            .plan {
                width: 100%;
                height: 100%;
                position: absolute;
                z-index: 1;
            }
        }
    }

   
    .sidebar {
        border-right: 1px solid #CED4DA;
        padding: 0;
        width: 300px;


        .floors-container {
            //overflow-y: scroll;
        }

        .header {
            border-bottom:  1px solid #CED4DA;
            background-color: rgba($color: #000000, $alpha: 0.05);
            display: flex;
            align-items: center;
            padding-left: 40px;
            min-height: 52px;
            color: #737980;
        }

        .floor-item {
            border-bottom: 1px solid #CED4DA;
            position: relative;
            padding: 0;
            cursor: pointer;

            .floor-item-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding: 10px;
                z-index: 1;
                position: relative;

                &:hover {
                    button {
                        visibility: visible;
                        background-color: inherit;
                        border: none;
                        border-radius: unset;
                        height: 100%;
                        &:active {
                            background-color: inherit;
                            border: none;
                            border-radius: unset;
                        }
                    }
                }
                button {
                    visibility: hidden;
                    background-color: inherit;
                    border: none;
                    border-radius: unset;
                    box-shadow: none;
                    &:active {
                        box-shadow: none;
                    }
                }
                .delete-button:hover {
                    //background-color: #CED4DA;
                }
                .item-selected:hover {
                    //background-color: #D9EAFF;
                }
            }

            .progress {
                background-color: #D9EAFF;
                height: 100%;
                position: absolute;
                // z-index: -1;
                transition: transform 500ms ease-in-out 25ms;
            }

            .active-marker {
                height: 100%;
                width: 5px;
                background-color: #007BFF;
                position: absolute;
                // z-index: -1;
            }

            .main-text {
                font-size: 12px;
                font-weight: 500;
            }

            .text-container {
                display: flex;
                flex-direction: row;
                column-gap: 10px;
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            .index {
                border: 1px solid #CED4DA;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 5px;
                min-width: 40px;
                font-size: 9px;
            }

            .indexInput {
                border: none;
                background: transparent;
                display: block;
                width: 30px;
            }
        }

        .floor-text {
            color: #DC3545;
            font-weight: 600;
            font-size: 20px;
            text-align: center;
            padding: 14px;
        }
    }

    .dropzone {
        background-color: rgba($color: #000000, $alpha: 0.05) ;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border: 1px dashed #CED4DA;
        min-height: 298px;
        border-radius: 12px;
        row-gap: 15px;

        .icon {
            width: 32px
        }

        .main-text {
            color: #737980;
            font-size: 16px;
            text-align: center;
        }

        .secondary-text {
            color: #737980;
            font-size: 12px;
            text-align: center;
        }

        .select-files {
            color: #007BFF;
            border: 1px solid #007BFF;
            background-color: transparent;
            border-radius: 4px;
            padding: 6px;
        }
    }
}